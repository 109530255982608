import API, { DisbursementItem, GetDisbursementsListResponse } from 'lib/api';
import { useEffect, useState } from 'react';
import { Col, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DisbursementListItem } from './DisbursementListItem';

const api = new API()

export function DisbursementsList() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [
    disbursementsList,
    setDisbursementsList,
  ] = useState<GetDisbursementsListResponse | null>(null)

  const [filteredDisbursementList, setFilteredDisbursementList] = useState<
    DisbursementItem[]
  >([])

  const getDisbursements = async () => {
    const disbursements = await api.GetAllDisbursements()
    setDisbursementsList(disbursements)
    setFilteredDisbursementList(disbursements.results)
    setIsLoading(false)
  }

  useEffect(() => {
    getDisbursements()
  }, [])

  const updateSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value

    const allResults = disbursementsList?.results || []

    if (!val) {
      setFilteredDisbursementList(allResults)
      return
    }

    setFilteredDisbursementList(
      allResults.filter((res) => {
        return [
          res.email,
          res.dob,
          res.payment_status,
          res.disbursement_amount,
        ].some((attr) => attr.toString().includes(val))
      }),
    )
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-lg w-100"
        onClick={() => navigate(`/disbursements/new`)}
      >
        New Disbursement
      </button>
      {isLoading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <Form.Group className="row">
            <Col sm={2} className="offset-sm-8 text-end">
              <Form.Label className="text-end">Search</Form.Label>
            </Col>
            <Col sm={2}>
              <Form.Control type="text" onChange={updateSearch}></Form.Control>
            </Col>
          </Form.Group>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Email</th>
                <th>DOB</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredDisbursementList.map((d) => (
                <DisbursementListItem
                  disbursement={d}
                  key={d.disbursement_id}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}
