import { DisbursementItem } from 'lib/api';

export function DisbursementListItem({
  disbursement,
}: {
  disbursement: DisbursementItem
}) {
  return (
    <tr>
      <td>{disbursement.disbursement_id}</td>
      <td>{disbursement.email}</td>
      <td>{disbursement.dob}</td>
      <td>{disbursement.disbursement_amount}</td>
      <td>{disbursement.payment_status}</td>
    </tr>
  )
}
