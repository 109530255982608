import { API_BASE_URI } from "lib/config";
import { getToken, setToken } from 'lib/session';

export type DisbursementItem = {
    disbursement_id: string;
    email: string;
    dob: string;
    disbursement_amount: number;
    payment_status: string;
}

export type GetDisbursementsListResponse = {
    success: boolean;
    results: DisbursementItem[];
}

export type CreateDisbursementRequest = {
    first_name: string;
    last_name: string;
    email: string;
    disbursement_amount: number;
    dob: string;
    ssn: string;
}

export type CreateDisbursementResponse = {
    success: boolean;
    disbursement_id: string;
}

export default class API {
    public async Authenticate (email: string, password: string) {
        const url = `${API_BASE_URI}/authenticate`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({email, password})
        });

        const json = await response.json() as { success: boolean, token?: string; };

        if (json.token) {
            setToken(json.token);
        }
        
        return json;
    }

    public async GetAllDisbursements() {
        const url = `${API_BASE_URI}/disbursements`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });

        const json = await response.json() as GetDisbursementsListResponse;
        
        return json;
    }

    public async CreateDisbursement(request: CreateDisbursementRequest) {
        const url = `${API_BASE_URI}/disbursements`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                Authorization: `Bearer ${getToken()}`,
            }
        });

        const json = await response.json() as CreateDisbursementResponse;
        
        return json;
    }
}