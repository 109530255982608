import API, { CreateDisbursementRequest } from 'lib/api';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const api = new API()

export function NewDisbursement() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)

    const target = e.target as typeof e.target & {
      first_name: { value: string }
      last_name: { value: string }
      email: { value: string }
      disbursement_amount: { value: string }
      dob: { value: string }
      ssn: { value: string }
    }

    const request : CreateDisbursementRequest = {
        first_name: target.first_name.value,
        last_name: target.last_name.value,
        email: target.email.value,
        disbursement_amount: parseFloat(target.disbursement_amount.value),
        dob: target.dob.value,
        ssn: target.ssn.value
    };

    const res = await api.CreateDisbursement(request);
    
    console.log(res)

    setIsLoading(false);

    navigate('/disbursements')
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="first_name" className="form-label">
            First Name
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Bill"
            name="first_name"
            id="first_name"
            minLength={2}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="last_name" className="form-label">
            Last Name
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Gates"
            name="last_name"
            id="last_name"
            minLength={2}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="bgates@microsoft.com"
            name="email"
            id="email"
            minLength={2}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="dob" className="form-label">
           Date of Birth
          </label>
          <input
            className="form-control"
            type="date"
            placeholder="1970-12-01"
            name="dob"
            id="dob"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="ssn" className="form-label">
            SSN
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="3421"
            name="ssn"
            id="ssn"
            minLength={4}
            maxLength={4}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="disbursement_amount" className="form-label">
            Disbursement Amount
          </label>
          <input
            className="form-control"
            type="number"
            placeholder="15000.01"
            step="0.01"
            name="disbursement_amount"
            id="disbursement_amount"
            minLength={5}
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-lg w-100"
          disabled={isLoading}
        >
          Create
        </button>
      </form>
    </>
  )
}
