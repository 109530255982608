import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from 'error-page';
import './index.css';
import Root from './routes/Root';

import API from 'lib/api';
import { NewDisbursement } from 'routes/NewDisbursement';
import { DisbursementsList } from './routes/DisbursementsList';
import { Login } from './routes/Login';

const api = new API();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'login/',
        element: <Login />
      },
      {
        path: 'disbursements/',
        element: <DisbursementsList />,
      },
      {
        path: 'disbursements/new',
        element: <NewDisbursement />,
      },
    ],
  },
])

function App() {  
  return (
    <React.StrictMode>
          {/* <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
          <img
            src={firmInfo?.logo_url}
            width="300"
            height="68"
            className="d-inline-block align-top"
            alt=""
          />
        </div> */}
        <div className="container">
          <RouterProvider router={router} />
        </div>
  </React.StrictMode>
  );
}

export default App;
