import API from 'lib/api';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const api = new API()

export function Login() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)

    const target = e.target as typeof e.target & {
        email: { value: string }
        password: { value: string }
    }

    const res = await api.Authenticate(target.email.value, target.password.value);

    console.log(res);

    navigate('/disbursements')
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="billgates@microsoft.com"
            name="email"
            id="email"
            minLength={5}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            className="form-control"
            type="password"
            placeholder=""
            name="password"
            id="password"
            minLength={5}
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-lg w-100"
          disabled={isLoading}
        >
          Next
        </button>
      </form>
    </>
  )
}
